<template>
  <body>
    <div id="wrapper">
      <header>
        <h1 id="titleHead">Nikolas Butalid</h1>
      </header>
      <nav id="myNav">
        <ul>
          <li v-on:click="component = 'home'">Home</li>
          <li v-on:click="component = 'about'">About</li>
          <li v-on:click="component = 'professional'">Resume</li>
          <li v-on:click="component = 'personal'">Personal Summary</li>
          <li v-on:click="component = 'contact'">Contact</li>
        </ul>
      </nav>

      <div id="textBody" class="clear">
        <component
          v-bind:is="component"
          v-on:changeComponent="updateComponent($event)"
        ></component>
      </div>

      <footer id="myFooter" class="clear">
        <div id="contactInfo">
          <!-- Email: butalidnik@yahoo.com <br />
          Phone: 919.376.5467 -->
        </div>
        <div id="links">
          <span class="link">
            <a
              href="https://www.linkedin.com/in/nikolas-butalid/"
              target="_blank"
              >LinkedIn</a
            >
          </span>
          |
          <span class="link">
            <a href="https://github.com/butalidn" target="_blank">Github</a>
          </span>
          |
          <span class="link">
            <a v-on:click="component = 'contact'" target="_blank">Contact Me</a>
          </span>
        </div>
        <br />
        <span id="CR">Copyright © 2022 Nikolas Butalid </span>
      </footer>
    </div>
  </body>
</template>

<script>
import Home from "./components/Home.vue";
import About from "./components/About.vue";
import Personal from "./components/Personal.vue";
import Professional from "./components/Professional.vue";
import Contact from "./components/Contact.vue";

export default {
  name: "App",
  components: {
    home: Home,
    personal: Personal,
    professional: Professional,
    contact: Contact,
    about: About,
  },
  data() {
    return {
      component: "home",
    };
  },
  methods: {
    updateComponent: function (updatedComponent) {
      this.component = updatedComponent;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
@import "./assets/style.css";
</style>
