<template>
  <!-- <span id="contactPage"> -->
  <h2 class="bodyHead">Send Me a Message!</h2>

  <form ref="form" @submit.prevent="sendEmail">
    <label>Name</label>
    <input
      id="user_name"
      type="text"
      name="user_name"
      placeholder="Your Name..."
    />
    <label>Email</label>
    <input
      id="email"
      type="email"
      name="user_email"
      placeholder="Your Email..."
    />
    <label>Message</label>
    <textarea
      id="message"
      name="message"
      style="height: 200px"
      placeholder="Write Something..."
    ></textarea>
    <input id="submit_btn" type="submit" value="Send" />
  </form>
  <!-- </span> -->
</template>

<script>
import emailjs from "@emailjs/browser";

export default {
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          "service_7srf5qr",
          "template_f6a08rn",
          this.$refs.form,
          "npX3VGRnOrfVgYXrI"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            const nameArea = document.getElementById("user_name");
            const emailArea = document.getElementById("email");
            const messageArea = document.getElementById("message");
            nameArea.value = "";
            emailArea.value = "";
            messageArea.value = "";
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    },
  },
};
</script>