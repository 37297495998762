<template>
    
    <h2 class="bodyHead">My Resume</h2>
    
    
    <h3>Profile</h3>
    

    <p class=text>
        Software engineer with a focus in data science, data engineering, and machine learning. Has a background in the event industry and is looking forward to delving deeper into the world of data. Critical thinker who enjoys learning new technologies and has excellent ability to work in teams with collaborative customer service and communication skills.
    </p>
        
    <h3>Education</h3>

    <p>
    <ul class="text">
        <li><a href="https://www.wgu.edu/" target="_blank">Western Governors University</a> | Bachelor of Science, Computer Science | May 2022</li>
        <li><a href="https://www.belmont.edu/" target="_blank">Belmont University</a> | Bacherlor of Science, Audio Engineering Technology w/ Music Business Minor, Cum Laude with GPA of 3.61 | December 2018</li>
    </ul>
    </p>
    
    <h3>Certications</h3>
    <p>
    <ul class="text">
        <li><a href="https://www.comptia.org/certifications/project" target="_blank">CompTIA Project+</a> | December 2021</li>
        <li><a href="https://www.peoplecert.org/browse-certifications/it-governance-and-service-management/ITIL-1/itil-4-foundation-2565" target="_blank">ITIL 4 Foundation</a> | November 2021</li>
    </ul>
    </p>

    <h3>Technical Skills</h3>

    <p>
    <ul class="text">
        <li>Proficient in Python, SQL, Java, Tableau, and Jupyter Notebook</li>
        <li>Familiar with Git, HTML, CSS, C++, C, AWS S3</li>
    </ul>
    </p>
    
    <h3>Projects</h3>
    <p>
        Github Link: <span class='gitlink'><a href="https://github.com/butalidn" target="_blank">https://github.com/butalidn</a></span>
    <ul class="text">
        <li>Developed a Jupyter Notebook dashboard application that analyzes the relationship between NBA player stats and their salaries. A machine learning linear regression estimator was utilized to predict a player’s estimated monetary value based on their season stats. The app was hosted using the Heroku Cloud Platform</li>
        <span class='gitlink'><a href="https://github.com/butalidn/NBA-Machine-Learning-Project" target="_blank">Link</a></span>
        <li>Created a Python-based solution that implements the Nearest Neighbors Algorithm to simulate the delivering of a number of packages under a specific total milage while also following other project conditions</li><span class='gitlink'><a href="https://github.com/butalidn/Delivery-Package-Project" target="_blank">Link</a></span>
        <li>Developed a GUI JDBC-based scheduling desktop application using Java and SQL. Interfaces with a MySQL database</li><span class='gitlink'><a href="https://github.com/butalidn/Scheduling-Application-Project" target='_blank'>Link</a></span>
        <li>Created a GUI inventory system in Java and JavaFX focusing on object-oriented design and principles</li><span class='gitlink'><a href="https://github.com/butalidn/Inventory-System-Project" target='_blank'>Link</a></span>
        <li>Implemented a text-based class roster system that parses and manipulates data</li><span class='gitlink'><a href="https://github.com/butalidn/Class-Roster-Project" target='_blank'>Link</a></span>
    </ul>
    </p>

    <h3>Related Coursework</h3>
    <p>
    <ul class="text">
        <li>Advanced Data Management</li>
        <li>Data Structures and Algorithms II</li>
        <li>Software II - Advanced Java Concepts</li>
        <li>Discrete Mathematics II</li>
    </ul>
    </p>

    <h3>Experience</h3>
    
    <p>
    <ul class="text">
        <li><b><a href="https://appen.com/" target="_blank">RaterLabs</a></b> | August 2019 - Present</li>
        <span class="jobTitle">Search Engine Evaluator</span>
        <ul>
            <li>Works independently to create qualitative data to determine the relevance and usefulness of search engine results</li>
        </ul><br>


        <li><b><a href="https://www.wgu.edu/" target="_blank">Western Governors University</a></b> | November 2021 - December 2021</li>
        <span class="jobTitle">Web Development Work Study</span>
        <ul>
            <li>Developed a serverless personal website using AWS S3 and AWS Lambda</li>
            <li>First created a static website using HTML and CSS, and then converted it into a dynamic and single-page application (SPA) site using Vue.js</li>
        </ul><br>
        <li><b><a href="https://nashvilleaudiovisual.com/" target="_blank">Nashville Audio Visual</a></b> | November 2019 - July 2020</li>
        <span class="jobTitle">Audio and Visual Technician</span>
        <ul>
            <li>Set up operated audio and video equipment including mixing boards, video screens, projectors, lighting systems, and related electronic equipment for presentations, cooperate events, conferences, weddings, and other private events</li>
        </ul><br>
        <li><b><a href="https://www.lipscomb.edu/" target="_blank">Lipscomb University</a></b> | April 2019 - November 2019</li>
        <span class="jobTitle">Entertainment and Technical Services Audio and Visual Technician</span>
        <ul>
            <li>Delivered, set up, and operated audio visual equipment for on-campus events</li>
            <li>Stage managed for the Gospel Music Association Immerse 2019 conference</li>
            <li>Ran audio for various theatre productions for Lipscomb Academy (“Lion King Jr.” and “Our Town”) </li>
        </ul><br>
        <li><b><a href="https://www.silentevents.com/" target="_blank">Silent Events</a></b> | November 2018 - April 2019</li>
        <span class="jobTitle">Headphone and Sound Technician</span>
        <ul>
            <li>Processed incoming stock of headphones and sound equipment</li>
            <li> Set up sound equipment and headphone distribution necessary for silent discos at various venues</li>
        </ul>
    </ul>
    </p>
</template>

<script>
export default {};
</script>

<style>
/* @import '/assets/style.css'; */
</style>