<template>
  <img
    id="faceImage"
    src="https://butalid-vue-lambda.s3.us-east-2.amazonaws.com/img/niks_face.3d3f9b8b.jpg"
    alt="Picture of Nik's face"
  />
  <!-- <img id="faceImage" :src="nikFace" alt="Picture of Nik's face"> -->

  <h2 class="bodyHead">Welcome!</h2>

  <p class="text">
    Hey everyone! My name is <span id="name">Nik Butalid</span>. Hopefully you
    can get to know me a little better through this site.
  </p>

  <p class="text">
    You'll find some information about some of my work experience, my interests,
    and other things about myself! If you have any questions don't be afraid to
    reach out to me! My information will be located below.
  </p>

  <p class="text">
    <b>For a quick summary: </b> <br />
    I am a recent WGU Computer Science graduate with experience in the music and
    event industry. I enjoy sports (specfically basketball), video games, trying
    new restaurants, going to parks, hanging with friends, and enjoying life to
    the fullest.
  </p>

  <p class="text">
    If you want to learn a little more about me, check out these pages!
  </p>

  <ul id="pagesList">
    <li v-on:click="changeComponent('about')">
      <span class="page">About</span>
    </li>
    <li v-on:click="changeComponent('professional')">
      <span class="page">Professional Summary</span>
    </li>
    <li v-on:click="changeComponent('personal')">
      <span class="page">Personal Summary</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Home",
  data: function () {
    return {
      nikFace: require("../assets/niks_face.jpg"),
    };
  },
  methods: {
    changeComponent: function (string) {
      this.$emit("changeComponent", string);
    },
  },
};
</script>