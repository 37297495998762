<template>
  <h2 class="bodyHead">Personal Summary</h2>

  <p class="text">
    Here's some more personal information and fun facts about myself!
    <img
      src="https://butalid-vue-lambda.s3.us-east-2.amazonaws.com/img/beach_Nik.93098f9e.jpeg"
      alt="Picture of Nik at a pier"
      class="pics"
      id="beachPic"
    />
  </p>

  <p class="text">
    I am a very easygoing and easy to be around kind of person. I'm not always
    one to talk a lot, but I enjoy listening to others and learning from others'
    perspective. I tend to approach things in a more logical way, but I can also
    embrace my creative side. I find joy in putting things in order and in their
    rightful place. I am very practical and like working with my hands. I like
    to learn about all sorts of things, but when I find something I am
    passionate about, I try to learn as much as I can about it. I believe it's
    important to approach everything with humility and grace. We can learn a lot
    from each other, especially from those who are different from us.
  </p>
  <p class="text">
    I am probably one of the biggest basketball fans you'll ever meet. I spend a
    majority of my time consuming basketball podcasts, YouTube videos, games,
    and playing basketball. I don't follow a single particular team, but I enjoy
    good basketball and witnessing the different stories in the NBA. I spend a
    lot of time working on music with my soon-to-be wife who is a songwriter in
    Nashville. I usually mix and record whatever music she's working on. I have
    played video games since I was a young boy. I play anything from sports
    games to shooters to MMORPGs to action games to more tactical or stealth
    games.
  </p>
  <p class="text">
    If you ever want to discuss anything programming, music, basketball, or
    gaming related, please do not hesitate to
    <a id="contact_link" v-on:click="changeComponent('contact')"
      >reach out to me!</a
    >
  </p>

  <div id="personalPics">
    <img
      src="https://butalid-vue-lambda.s3.us-east-2.amazonaws.com/img/sea_Nik.eab8c8c3.jpg"
      alt="Picture of Nik at the Sea of Galilee"
      class="bottomPic"
    />
    <img
      src="https://butalid-vue-lambda.s3.us-east-2.amazonaws.com/img/sweater_Nik.b3a7a130.jpg"
      class="bottomPic"
    />
  </div>
</template>

<script>
export default {
  name: "Home",
  data: function () {
    return {
      seaNik: require("../assets/sea_Nik.jpg"),
      sweaterNik: require("../assets/sweater_Nik.jpg"),
      beachNik: require("../assets/beach_Nik.jpeg"),
    };
  },
  methods: {
    changeComponent: function (string) {
      this.$emit("changeComponent", string);
    },
  },
};
</script>