<template>
  <h2 class="bodyHead">About Me</h2>
  <img
    src="https://butalid-vue-lambda.s3.us-east-2.amazonaws.com/img/graduate_Nik.5c04ea87.jpg"
    class="pics"
    id="gradPic"
  />

  <p class="text">
    Hi, I'm Nik! I just recently graduated from Western Governors University
    (WGU) and received my second bachelors in Computer Science. I previously
    received my B.S. in Audio Engineering at Belmont University. I am based in
    Nashville, TN, but I am originally from Cary, NC.
  </p>
  <img
    src="https://butalid-vue-lambda.s3.us-east-2.amazonaws.com/img/studio_Nik.5ebba2ac.jpg"
    alt="Picture of Nik in the studio"
    class="pics"
    id="studioPic"
  />
  <p class="text">
    I have a deep love for music, which led me to Belmont and Nashville. I
    mostly play the guitar, piano, and bass. I enjoy playing music, but my
    strengths are more in the production and engineering side of music, i.e.,
    mixing and tracking. I am trained in working in professional recording
    studios and have a pretty good ear for the finer details of music. I've also
    worked in corporate settings doing audio/visual production for live events.
  </p>

  <p class="text">
    Because of many factors, in 2020 I decided to begin pursuing a career in the
    Software Engineering and Data world. In November of 2020, I began my journey
    at WGU. I had taken a few entry-level programming classes in high school,
    but other than that, I had pretty minimal experience in programming and
    anything software related.
  </p>
  <p class="text">
    I have a lot to learn, but I've enjoyed learning new programming languages
    and how to think more critcally. I have an interest in the world of data and
    data science, and I look forward to working in that field. I think it's
    fascinating how much data is out there and how much knowledge can be
    extracted from it. Whatever I end up doing, I hope to use my knowledge and
    skills to have a real tangible and positive impact on the world in any way I
    can.
  </p>
</template>

<script>
export default {
  name: "Home",
  data: function () {
    return {
      studioNik: require("../assets/studio_Nik.jpg"),
      gradNik: require("../assets/graduate_Nik.jpg"),
    };
  },
};
</script>